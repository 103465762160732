import "../styles/button.css";

function DopeButton(props) {
  return (
    <button
      onClick={props.onclick}
      disabled={props.disabled}
      className="dope-btn"
    >
      {props.children}
    </button>
  );
}

export default DopeButton;
