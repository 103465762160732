import SectionHeading from "./SectionHeading";
import TextAreaWithButton from "./TextAreaWithButton";
import "../styles/greeting.css";

function Greeting(props) {
  return (
    <>
      <SectionHeading>Greeting</SectionHeading>
      <div className="greeting-container">
        <p className="greeting-heading">Set a Greeting Phrase:</p>
        <p className="greeting-description">
          This is the phrase Maddy will use before each answer to greet the
          asker.
        </p>
        <TextAreaWithButton
          placeholder={props.user.messageGreeting || "Hey!"}
          size={"small"}
          user={props.user}
          type="greeting"
        >
          Edit
        </TextAreaWithButton>
      </div>
    </>
  );
}

export default Greeting;
