import PriceCard from "./PriceCard";
import "../styles/payment.css";

function Payment(props) {
  return (
    <div className="payment-container">
      <h2 className="payment-heading">
        Get started by subscribing to a Plan from below.
      </h2>
      <p className="payment-description">
        You can add the bot to you server and start training it right after you
        subscribe.
      </p>
      <div className="pricecard-list">
        <PriceCard
          price="20"
          offerings={["Ask 750 Questions every month.", "Train 50 web pages."]}
          color="grey"
          user={props.user}
        />
        <PriceCard
          price="40"
          offerings={[
            "Ask 1500 Questions every month.",
            "Train 200 web pages.",
          ]}
          color="peach"
          user={props.user}
        />
        <PriceCard
          price="50"
          offerings={[
            "Ask unlimited Questions every month.",
            "Train unlimited web pages.",
            "Early access to new features.",
          ]}
          color="purple"
          user={props.user}
        />
      </div>
    </div>
  );
}

export default Payment;
