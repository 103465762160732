import "../styles/pricecard.css";
import SubscribeButton from "./SubscribeButton";

function PriceCard(props) {
  return (
    <div className={`price-card ${props.color}`}>
      <p className="price-heading">${props.price}/month</p>
      <ul className="price-offerings">
        {props.offerings.map((offering) => {
          return <li>{offering}</li>;
        })}
      </ul>
      <div className="subscribe-btn">
        <SubscribeButton plan={props.color} user={props.user}>
          Subscribe
        </SubscribeButton>
      </div>
    </div>
  );
}

export default PriceCard;
