import "../styles/button.css";

function PurpleButton(props) {
  return (
    <button onClick={props.onClick} className="purple-btn">
      {props.children}
    </button>
  );
}

export default PurpleButton;
