import "../styles/textareawithbutton.css";
import DopeButton from "./DopeButton";
import { useRef, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

function TextAreaWithButton(props) {
  const ref = useRef(null);

  const handleInput = () => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_BACKEND_TOKEN}`,
    },
  };

  const handleSubmit = async (e) => {
    if (props.type === "onboarding") {
      await axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/profile/${props.user.id}/`,
          { onboardingMessage: ref.current.value },
          config
        )
        .then(() => {
          toast("Onboarding Message Saved!");
        })
        .catch((e) => {
          toast("Something went wrong!", { icon: "🫠" });
        });
    } else if (props.type === "channel") {
      await axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/profile/${props.user.id}/`,
          { channelName: ref.current.value },
          config
        )
        .then(() => {
          toast("Channel Saved!");
        })
        .catch((e) => {
          toast("Something went wrong!", { icon: "🫠" });
        });
    } else if (props.type === "greeting") {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/profile/${props.user.id}/`,
          { messageGreeting: ref.current.value },
          config
        )
        .then(() => {
          toast("Greeting Saved!");
        })
        .catch((e) => {
          toast(e.response.data.error, { icon: "🫠" });
        });
    } else if (props.type === "defaultChannel") {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/profile/${props.user.id}/`,
          { defaultChannels: { channels: [ref.current.value] } },
          config
        )
        .then(() => {
          toast("Channel Saved!");
        })
        .catch((e) => {
          toast(e.response.data.error, { icon: "🫠" });
        });
    }
    console.log(ref.current.value);
  };

  useEffect(() => {
    if (props.size === "small") {
      ref.current.style.minHeight = "0px";
    }
    if (props.type === "channel" && !props.placeholder) {
      ref.current.placeholder = "Enter Channel Name";
    }
  });

  return (
    <div className="container">
      <textarea
        placeholder={props.placeholder}
        ref={ref}
        rows={2}
        onInput={handleInput}
      />
      <DopeButton onclick={handleSubmit}>{props.children}</DopeButton>
    </div>
  );
}

export default TextAreaWithButton;
