import "../styles/featurecard.css";

function FeatureCard(props) {
  return (
    <div className="feature-card">
      <div className="feature">
        <img src={props.src} className="feature-icon" alt="Feature Icon" />
        <h2 className="feature-heading">{props.heading}</h2>
        <p className="feature-description">{props.description}</p>
      </div>
    </div>
  );
}

export default FeatureCard;
