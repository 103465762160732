import "../styles/landing.css";
import PurpleButton from "./PurpleButton";
import BlackButton from "./BlackButton";
import { useAuth0 } from "@auth0/auth0-react";

function Landing() {
  const { loginWithPopup, isAuthenticated } = useAuth0();

  const openNewRoute = () => {
    window.open("/dashboard", "_self");
  };

  const bookCall = () => {
    window.open("https://calendly.com/anushkavy/madlad-book-a-demo-call-1");
  };

  return (
    <div className="landing">
      <div className="landing-left">
        <h1 className="landing-headline">
          A bot that can do anything a community moderator can.
        </h1>
        <p className="landing-desc">
          Train the bot, add to chat, witness its human-like behavior—better
          than human mods lol
        </p>
      </div>
      <div className="landing-right">
        <div className="image-holder">
          <img
            className="landing-logo"
            src="landing-logo.svg"
            alt="Maddy Logo"
          />
          <div className="relative-btn">
            <PurpleButton
              onClick={() => {
                !isAuthenticated ? loginWithPopup() : openNewRoute();
              }}
            >
              {!isAuthenticated ? "Add me to Discord" : "View Dashboard"}
            </PurpleButton>
            <BlackButton onclick={bookCall}>Book a call</BlackButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
