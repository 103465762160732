import SectionHeading from "./SectionHeading";
import TextAreaWithButton from "./TextAreaWithButton";
import "../styles/customisations.css";

export default function Customisations(props) {
  return (
    <>
      <SectionHeading>MORE CUSTOMISATIONS</SectionHeading>
      <div className="customisations-container">
        <p className="customisations-heading">
          Don't wanna mention @madlad to ask a question?
        </p>
        <p className="customisations-description">
          Once you enter the name of a channel, Maddy will reply to every
          message sent in there irrespective of the @madlad tag.
        </p>
        <p className="customisations-heading">Specify a channel below:</p>
        <TextAreaWithButton
          placeholder={props.user.defaultChannels?.channels?.[0] || ""}
          size={"small"}
          user={props.user}
          type="defaultChannel"
        >
          Save
        </TextAreaWithButton>
      </div>
    </>
  );
}
