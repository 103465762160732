import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "./Loading";
import Navbar from "./Navbar";
import "../styles/dashboard.css";
import Payment from "./Payment";
import Training from "./Training";
import Onboarding from "./Onboarding";
import Greeting from "./Greeting";
import Footer from "./Footer";
import SectionHeading from "./SectionHeading";
import ToggleButton from "./ToggleButton";
import Customisations from "./Customisations";

function Dashboard() {
  const { user } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_BACKEND_TOKEN}`,
    },
  };
  useEffect(() => {
    async function fetchProfiles() {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/profile`,
        config
      );
      const isProfilePresent = response.data.find((profile) => {
        return user.email === profile.email;
      });
      if (!isProfilePresent) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/profile`,
          {
            name: user.name,
            email: user.email,
            image: user.picture,
          },
          config
        );
        window.location.reload();
      } else {
        setCurrentUser(isProfilePresent);
      }
      setIsLoading(false);
    }

    fetchProfiles();
  }, []);
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Navbar />
      <h1 className="dashboard-greeting">Heyo {currentUser.name}!👋</h1>
      {currentUser.status === "UNPAID" ? (
        <Payment user={user} />
      ) : (
        <>
          <Training
            email={currentUser.email}
            language={currentUser.contentLanguage}
            userId={currentUser.id}
          />
          {currentUser.plan === "Dominion" ? (
            <Onboarding user={currentUser} />
          ) : null}
          <Greeting user={currentUser} />
          <Customisations user={currentUser} />
        </>
      )}
      <Footer />
    </>
  );
}

export default Dashboard;
