import "../styles/button.css";
import { useAuth0 } from "@auth0/auth0-react";

function SubscribeButton(props) {
  const { isAuthenticated, loginWithPopup, user } = useAuth0();

  function handleCheckout() {
    switch (props.plan) {
      case "grey":
        window.open(
          `${process.env.REACT_APP_PLAYGROUND_LINK}?prefilled_email=${user.email}`,
          "_self"
        );
        break;
      case "peach":
        window.open(
          `${process.env.REACT_APP_ARENA_LINK}?prefilled_email=${user.email}`,
          "_self"
        );
        break;
      default:
        window.open(
          `${process.env.REACT_APP_DOMINION_LINK}?prefilled_email=${user.email}`,
          "_self"
        );
    }
  }
  return (
    <button
      onClick={isAuthenticated ? handleCheckout : loginWithPopup}
      className="black-btn"
    >
      {props.children}
    </button>
  );
}

export default SubscribeButton;
