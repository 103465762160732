import "../styles/footer.css";
import TextLink from "./TextLink";

function Footer() {
  return (
    <div className="footer">
      <div className="top-footer">
        <img className="footer-logo" src="footer-logo.png" alt="Maddy Logo" />
        <div className="footer-row">
          <div className="footer-column">
            <TextLink to={"/#working"}>How do I work?</TextLink>
            <TextLink to={"/#pricing"}>Pricing</TextLink>
          </div>
          <div className="footer-column">
            <TextLink
              to={"https://calendly.com/anushkavy/madlad-book-a-demo-call-1"}
              external
            >
              Book a call
            </TextLink>
            <TextLink to={"https://www.twitter.com/madladAI/"} external>
              Follow me on Twitter
            </TextLink>
          </div>
          <div className="footer-column">
            <TextLink to={process.env.REACT_APP_DISCORD_LINK} external>
              Join my Discord
            </TextLink>
            <TextLink
              to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
              external
            >
              Contact Support
            </TextLink>
          </div>
          <div className="footer-column">
            <TextLink to={"/privacy-policy"}>Privacy Policy</TextLink>
            <TextLink to={"/terms-and-conditions"}>
              Terms and conditions
            </TextLink>
          </div>
        </div>
      </div>
      <div className="lower-footer">
        Built by{" "}
        <a
          href="https://www.twitter.com/argent_codes"
          target="_blank"
          className="footer-link"
          rel="noopener noreferrer"
        >
          Rajat
        </a>{" "}
        and{" "}
        <a
          href="https://www.twitter.com/anushka_twt"
          target="_blank"
          className="footer-link"
          rel="noopener noreferrer"
        >
          Anushka
        </a>
      </div>
    </div>
  );
}

export default Footer;
