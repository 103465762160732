import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "./Navbar";
import Landing from "./Landing";
import Metrics from "./Metrics";
import Working from "./Working";
import Features from "./Features";
import Pricing from "./Pricing";
import Footer from "./Footer";

function Home() {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <Navbar isAuthenticated={isAuthenticated} />
      <Landing />
      <Metrics />
      <Working />
      <Features />
      <Pricing />
      <Footer />
    </>
  );
}

export default Home;
