import "../styles/navbar.css";
import PurpleButton from "./PurpleButton";
import TextLink from "./TextLink";
import { useAuth0 } from "@auth0/auth0-react";

function Navbar(props) {
  const { loginWithPopup, isAuthenticated } = useAuth0();

  const openNewRoute = () => {
    window.open("/dashboard", "_self");
  };

  return (
    <nav className="navbar">
      <h1 className="nav-heading">
        <a href="/">MADLAD</a>
      </h1>
      <div className="nav-links">
        <TextLink to={"/#working"}>How do I work?</TextLink>
        <TextLink to={"/#pricing"}>Pricing</TextLink>
        <TextLink to={process.env.REACT_APP_DISCORD_LINK} external>
          Join my Discord
        </TextLink>
        <PurpleButton
          onClick={() => {
            !isAuthenticated ? loginWithPopup() : openNewRoute();
          }}
        >
          {!isAuthenticated ? "Add me to Discord" : "View Dashboard"}
        </PurpleButton>
      </div>
    </nav>
  );
}

export default Navbar;
