import "../styles/features.css";
import FeatureCard from "./FeatureCard";

function Features() {
  return (
    <div className="features">
      <div>
        <h2 className="features-heading">
          Just a few clicks, and you have a Personal Community Moderator that
          works 24/7.
        </h2>
        <p className="features-description">
          Get your own personalized community bot in less than 15 minutes.
        </p>
      </div>
      <div className="feature-list">
        <FeatureCard
          src="invite-icon.svg"
          heading="Invite"
          description="Add the bot to your discord community."
        />
        <FeatureCard
          src="train-icon.svg"
          heading="Train"
          description="Train the bot with all the links (information) you want it to know about."
        />
        <FeatureCard
          src="ask-icon.svg"
          heading="Ask"
          description="Let the community ask questions & see Maddy replying perfectly."
        />
      </div>
    </div>
  );
}

export default Features;
