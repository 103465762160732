import "../styles/working.css";

function Working() {
  return (
    <div className="working" id="working">
      <h1 className="working-headline">How do I work?</h1>
      <div className="video-container">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/3uzqtFMsJJc`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
}

export default Working;
