import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Home from "./parts/Home";
import AuthenticationGuard from "./parts/AuthenticationGuard";
import Dashboard from "./parts/Dashboard";
import Loading from "./parts/Loading";
import DiscordAuth from "./parts/DiscordAuth";
import PrivacyPolicy from "./parts/PrivacyPolicy";
import TermsAndConditions from "./parts/TermsAndConditions";

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route
            path="dashboard"
            element={<AuthenticationGuard component={Dashboard} />}
          />
          <Route
            path="auth/discord"
            element={<AuthenticationGuard component={DiscordAuth} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
