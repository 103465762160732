import "../styles/termsandconditions.css";

function TermsAndConditionsContent() {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>
      <p className="margin-bottom">
        Welcome to the terms and conditions for the Madlad AI bot. By using
        Madlad, you agree to be bound by these terms and conditions, which
        constitute a legal agreement between you and Madlad AI. If you do not
        agree to these terms and conditions, please do not use Madlad.
      </p>
      <ol>
        <li className="margin-bottom">
          <span className="underlined-text">Description of Madlad AI:</span>{" "}
          Madlad AI is a community moderation bot that uses natural language
          processing to perform the same functions as a human community
          moderator. Madlad AI is designed to work with a variety of online
          communities, including forums, social media platforms, and chat rooms
          with all features being launched gradually.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Limitations of Madlad AI:</span>{" "}
          While Madlad AI is designed to be a highly effective community
          moderator, it is not perfect. There may be situations where Madlad AI
          does not perform as expected, or where its actions are not
          appropriate. Madlad AI is not responsible for any damages or losses
          that may result from its use.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Privacy:</span> Madlad AI respects
          your privacy and is committed to protecting your personal information.
          Madlad AI may collect certain information from you, such as your IP
          address and training data, in order to improve its services. Madlad AI
          will never share your personal information with third parties unless
          required by law.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Termination:</span> Madlad AI
          reserves the right to terminate your use of its services at any time,
          without notice or liability. If you violate any of these terms and
          conditions, your access to Madlad AI may be suspended or terminated.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Disclaimer of Warranties:</span>{" "}
          Madlad AI provides its services "as is" and without any warranty or
          guarantee. Madlad AI does not guarantee that its services will be
          error-free, uninterrupted, or free of viruses or other harmful
          components.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Limitation of Liability:</span>{" "}
          Madlad AI will not be liable for any direct, indirect, incidental,
          consequential, or punitive damages that result from your use of its
          services. In no event will Madlad AI's liability exceed the amount
          paid by you, if any, for access to its services.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Indemnification:</span> You agree to
          indemnify and hold Madlad AI harmless from any claims, damages,
          losses, or expenses that arise from your use of its services or from
          any violation of these terms and conditions.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">
            Changes to Terms and Conditions:
          </span>{" "}
          Madlad AI reserves the right to update or modify these terms and
          conditions at any time. By continuing to use Madlad AI after such
          changes are made, you agree to be bound by the revised terms and
          conditions.
        </li>
      </ol>

      <h1>Billing & Refunds</h1>
      <ol>
        <li className="margin-bottom">
          <span className="underlined-text">Billing:</span> Madlad charges a
          monthly fee for access to its services. The current pricing can be
          found on the website www.madlad.ai. By subscribing to Madlad AI, you
          agree to pay the monthly fee in advance.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Refunds:</span> Due to the nature of
          our product, we do not offer refunds, either partial or in full, once
          a payment has been made.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Cancellation:</span> You can cancel
          your subscription to Madlad at any time by contacting our customer
          support team. Your subscription will remain active until the end of
          the billing cycle in which you cancel.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">
            Changes to Pricing and Features:
          </span>{" "}
          Madlad reserves the right to change its pricing, pricing policies,
          features, and access restrictions at any time. Any changes to pricing
          or features will be communicated to subscribers in advance.
        </li>
      </ol>

      <h1>Emails</h1>
      <p className="email-note">
        Madlad may use your email address to contact you about your account,
        product updates, and other marketing activities. By subscribing to
        Madlad, you agree to receive these emails. You can unsubscribe from
        Madlad's emails at any time by clicking the unsubscribe link at the
        bottom of the email.
      </p>

      <p className="thanks-note">
        Thank you for using Madlad. If you have any questions or concerns,
        please contact us at{" "}
        <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </a>
        .
      </p>
    </div>
  );
}

export default TermsAndConditionsContent;
