import { Oval } from "react-loader-spinner";
import "../styles/loading.css";

function Loading() {
  return (
    <div className="loading">
      <Oval
        height={80}
        width={80}
        color="purple"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="pink"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
}

export default Loading;
