import "../styles/metrics.css";
import { useState, useEffect } from "react";
import axios from "axios";

function Metrics() {
  const [questions, setQuestions] = useState(0);
  const [users, setUsers] = useState(0);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_BACKEND_TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/question-count`, config)
      .then((response) => {
        setQuestions(response.data.count);
      });

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/user-count`, config)
      .then((response) => {
        setUsers(response.data.count);
      });
  });

  return (
    <div className="metrics">
      <p>
        Used by <span className="number">27.6K+</span> Members of{" "}
        <span className="number">5</span> Communities and answered{" "}
        <span className="number">{questions}</span> Questions of{" "}
        <span className="number">{users}</span> Unique askers
      </p>
    </div>
  );
}

export default Metrics;
