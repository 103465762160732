import Navbar from "./Navbar";
import Footer from "./Footer";
import TermsAndConditionsContent from "./TermsAndConditionsContent";

function TermsAndConditions() {
  return (
    <>
      <Navbar />
      <TermsAndConditionsContent />
      <Footer />
    </>
  );
}

export default TermsAndConditions;
