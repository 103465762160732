import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain="argent.us.auth0.com"
    clientId="dBDkdeNgfTuLeGgS2PXorpCTw6B683A3"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <App />
    <Toaster position="top-right" reverseOrder={false} />
  </Auth0Provider>
  // </React.StrictMode>
);
