import { useLocation } from "react-router-dom";
import Loading from "./Loading";
import { useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

function DiscordAuth() {
  const { search } = useLocation();
  const { user } = useAuth0();
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get("code");
    const guildId = searchParams.get("guild_id");
    const config = {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_BACKEND_TOKEN}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/discord-auth`,
        {
          email: user.email,
          guildId: guildId,
          code: code,
        },
        config
      )
      .then((response) => {
        window.open("/dashboard", "_self");
      })
      .catch((e) => {
        window.open("/dashboard", "_self");
      });
  });
  return <Loading />;
}

export default DiscordAuth;
