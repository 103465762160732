import "../styles/privacy.css";

function Privacy() {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <p className="margin-bottom">
        Madlad AI ("Madlad", "we", "us", or "our") is committed to protecting
        the privacy of its users. This privacy policy explains how we collect,
        use, and disclose personal information in connection with our website (
        <a href="https://madlad.ai">www.madlad.ai</a>) and our community
        moderation bot (the "Bot").
      </p>
      <ol>
        <li className="margin-bottom">
          <span className="underlined-text">Information We Collect:</span> We
          collect personal information such as your name, email address, and
          payment information when you create an account or subscribe to our
          services. We also collect information about your use of the Bot,
          including the communities where you use it and the actions you take.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">How We Use Your Information:</span>{" "}
          We use this information to provide and improve our services,
          communicate with you, and personalize your experience with the Bot. We
          may share your information with third parties such as service
          providers, business partners, and law enforcement authorities when
          required by law or to protect our rights and interests.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Data Security:</span> We take
          appropriate technical and organizational measures to protect the
          personal information we collect from unauthorized access, disclosure,
          or destruction. We retain personal information for as long as
          necessary to provide our services and comply with legal obligations.
        </li>
        <li className="margin-bottom">
          <span className="underlined-text">Your Rights:</span> You have certain
          rights with respect to your personal information, including the right
          to access, correct, delete, or restrict the use of your personal
          information. We may update this privacy policy from time to time and
          will notify you of any material changes.
        </li>
      </ol>
      <p className="privacy-note">
        Thank you for using Madlad. If you have any questions or concerns,
        please contact us at{" "}
        <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </a>
        .
      </p>
    </div>
  );
}

export default Privacy;
