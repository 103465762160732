import "../styles/togglebutton.css";
import { useState } from "react";

export default function ToggleButton(props) {
  const [isToggled, setIsToggled] = useState(props.default);
  const handleToggle = () => {
    setIsToggled(!isToggled);
    props.onChange(!isToggled);
  };

  return (
    <div className={`toggle-container ${isToggled ? "toggled" : ""}`}>
      <button className="toggle-button" onClick={handleToggle}>
        {isToggled ? "ON" : "OFF"}
      </button>
    </div>
  );
}
