import "../styles/pricing.css";
import PriceCard from "./PriceCard";

function Pricing() {
  return (
    <div className="pricing" id="pricing">
      <h1 className="pricing-heading">Pricing</h1>
      <h2 className="price-subheading">
        I have got Pricing plans that'll suit <br /> requirements of all types
        of communities{" "}
      </h2>
      <p className="price-description">
        Now you don't need to think twice before using me ;){" "}
      </p>
      <div className="price-list">
        <PriceCard
          price="20"
          offerings={["Ask 750 Questions every month.", "Train 50 web pages."]}
          color="grey"
        />
        <PriceCard
          price="40"
          offerings={[
            "Ask 1500 Questions every month.",
            "Train 200 web pages.",
          ]}
          color="peach"
        />
        <PriceCard
          price="50"
          offerings={[
            "Ask unlimited Questions every month.",
            "Train unlimited web pages.",
            "Early access to new features.",
          ]}
          color="purple"
        />
      </div>
    </div>
  );
}

export default Pricing;
