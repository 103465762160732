import PurpleButton from "./PurpleButton";
import "../styles/training.css";
import DopeButton from "./DopeButton";
import SectionHeading from "./SectionHeading";
import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Select from "react-select";

function Training(props) {
  const [trainingStatus, setTrainingStatus] = useState("NOT STARTED");
  const [inputURL, setInputURL] = useState("");
  const [languages, setLanguages] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/supported-languages`)
      .then((response) => response.json())
      .then((data) => {
        setLanguages(data.languages);
        setSelectedLanguage(props.language);
      })
      .catch((error) => console.error("Error fetching languages:", error));
  }, []);

  const options = Object.entries(languages).map(([code, name]) => ({
    value: code,
    label: name,
  }));

  const handleSelect = async (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
    const config = {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_BACKEND_TOKEN}`,
      },
    };

    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/profile/${props.userId}/`,
        { contentLanguage: selectedOption.value },
        config
      )
      .then(() => {
        toast("Language Saved!");
      })
      .catch((e) => {
        toast("Something went wrong!", { icon: "🫠" });
      });
  };

  const validateURL = (inputURL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(inputURL);
  };

  const renderButtonText = () => {
    switch (trainingStatus) {
      case "IN PROGRESS":
        return "TRAINING";
      case "COMPLETED":
        return "TRAINED";
      default:
        return "TRAIN";
    }
  };

  const trainURL = async () => {
    if (validateURL(inputURL)) {
      setTrainingStatus("IN PROGRESS");
      const config = {
        headers: {
          Authorization: `Token ${process.env.REACT_APP_BACKEND_TOKEN}`,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/train`,
          {
            url: inputURL,
            email: props.email,
          },
          config
        )
        .then(
          (response) => {
            toast("Yay, now I have learned everything from this page!", {
              icon: "😎",
            });
            setTrainingStatus("COMPLETED");
          },
          (e) => {
            toast(
              e.response ? e.response.data.error : "Something went wrong!",
              {
                icon: e.response
                  ? e.response.data.error.includes("uh -oh")
                    ? "🫣"
                    : "🫠"
                  : "🫠",
              }
            );
            setTrainingStatus("NOT STARTED");
          }
        );
    } else {
      toast("Please enter a valid URL!", { icon: "🫠" });
    }
  };

  const addToDiscord = () => {
    window.open(process.env.REACT_APP_DISCORD_BOT_OAUTH, "_self");
  };

  const handleInput = (e) => {
    setInputURL(e.target.value);
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  return (
    <>
      <div className="training">
        <p>Add Maddy to your server and start training!</p>
        <PurpleButton onClick={addToDiscord}>Add me to Discord</PurpleButton>
        <p className="server-warning">
          You can only add me to one server, be careful!
        </p>
      </div>
      <SectionHeading>TRAIN ME HERE</SectionHeading>
      <div className="input-container">
        <p>What's the primary language of your documentation?</p>
        <Select
          options={options}
          value={options.find((option) => option.value === selectedLanguage)}
          onChange={handleSelect}
          placeholder="Type to search languages..."
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              background: "#141414",
              boxShadow:
                "5px -6px 4px 0px rgba(0, 0, 0, 0.25) inset, 5px 6px 4px 0px rgba(0, 0, 0, 0.25) inset",
              border: "none",
              padding: "0 0 0 40px",
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#141414",
              cursor: "pointer",
              fontFamily: "Noto Sans",
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: "white",
              fontFamily: "Noto Sans",
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              fontFamily: "Noto Sans",
              color: "white",
            }),
          }}
        />
        <p>Please add the link of the page you want to train me:</p>
        <input
          onChange={handleInput}
          placeholder="ENTER URL"
          type="url"
          className="training-input"
        />
        <div className="train-btn">
          <DopeButton onclick={trainURL}>{renderButtonText()}</DopeButton>
        </div>
        <div className="btn-list">
          <></>
          {/* <DopeButton>View Trained URLs</DopeButton> */}
          <DopeButton onclick={refreshPage}>Train more</DopeButton>
        </div>
        <p className="usage-guide">
          The community can start asking questions once you add and train the
          bot by mentioning
          <br />
          @madlad before their question.
        </p>
      </div>
    </>
  );
}

export default Training;
