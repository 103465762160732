import SectionHeading from "./SectionHeading";
import TextAreaWithButton from "./TextAreaWithButton";
import "../styles/onboarding.css";
import ToggleButton from "./ToggleButton";
import axios from "axios";
import toast from "react-hot-toast";

function Onboarding(props) {
  const updateOnboarding = async (isToggled) => {
    const config = {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_BACKEND_TOKEN}`,
      },
    };
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/profile/${props.user.id}/`,
        { onboarding: isToggled },
        config
      )
      .then(() => {
        toast("Onboarding Saved!");
      })
      .catch((e) => {
        toast("Something went wrong!", { icon: "🫠" });
      });
  };
  return (
    <>
      <SectionHeading>
        ONBOARDING{" "}
        <ToggleButton
          onChange={updateOnboarding}
          default={props.user.onboarding}
        />
      </SectionHeading>
      <div className="onboarding-container">
        <p className="onboarding-heading">Set an onboarding message:</p>
        <p className="onboarding-description">
          This is the message each new joiner in the community will get to let
          them know that Maddy will help them guide through the server.
        </p>
        <TextAreaWithButton
          placeholder={
            props.user.onboardingMessage
              ? props.user.onboardingMessage
              : process.env.REACT_APP_DEFAULT_ONBOARDING_MESSAGE
          }
          user={props.user}
          type="onboarding"
        >
          Edit Message
        </TextAreaWithButton>
        <p className="phrase-heading">
          Use these phrases to perform dynamic functions:
        </p>
        <div className="dynamic-function-container">
          <div className="dynamic-function">
            <span className="dynamic-function-key">{"<mention.new.user>"}</span>
            <span className="dynamic-function-value">
              To ping the new user who just joined the community.
            </span>
          </div>
          <div className="dynamic-function">
            <span className="dynamic-function-key">{"<server.name>"}</span>
            <span className="dynamic-function-value">
              To fetch the name of your Discord community.
            </span>
          </div>
          <div className="dynamic-function">
            <span className="dynamic-function-key">{"<mention.madlad>"}</span>
            <span className="dynamic-function-value">
              To mention Madlad in the message.
            </span>
          </div>
          <div className="dynamic-function">
            <span className="dynamic-function-key">
              {"<mention.channel.channelID>"}
            </span>
            <span className="dynamic-function-value">
              {
                'To mention a channel in the message. Please put the id of the channel you need to mention in "channelID". Example- <mention.channel.1115606979499540571>'
              }
            </span>
          </div>
        </div>
        <p>Specify a channel to send the onboarding messages:</p>
        <TextAreaWithButton
          placeholder={props.user.channelName}
          user={props.user}
          type={"channel"}
          size={"small"}
        >
          Edit
        </TextAreaWithButton>
      </div>
    </>
  );
}

export default Onboarding;
