import "../styles/button.css";

function BlackButton(props) {
  return (
    <button className="black-btn" onClick={props.onclick}>
      {props.children}
    </button>
  );
}

export default BlackButton;
