import "../styles/button.css";
import { HashLink } from "react-router-hash-link";

function TextLink(props) {
  return props.external ? (
    <a
      href={props.to}
      className="text-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  ) : (
    <HashLink to={props.to} className="text-link">
      {props.children}
    </HashLink>
  );
  // }
}

export default TextLink;
